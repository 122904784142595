import React, { useContext } from "react";

import axios from "axios";
import { navigate } from "@reach/router";
import { useGoogleLogin } from "react-google-login";

import Navbar from "./Navbar";
import google from "../images/google.svg";
import ThemeContext from "./ThemeContext";

import { CLIENT_ID, API_USERS, API_USERS_HISTORY } from "./fileWithConstants";

const Login = () => {
  const [theme, setTheme] = useContext(ThemeContext);

  const onSuccess = (response) => {
    axios.post(API_USERS, {
      name: response.profileObj.name,
      email: response.profileObj.email,
    });

    axios.post(API_USERS_HISTORY, {
      email: response.profileObj.email,
    });

    navigate("/");
    setTheme({
      name: response.profileObj.givenName,
      id: response.profileObj.email,
      userLoggedIn: true,
    });
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    clientId: CLIENT_ID,
    isSignedIn: true,
  });

  return (
    <div>
      <Navbar />
      <div className="container">
        <h3 className="text-center">
          Please login <span> and help explain tech to the world</span>
        </h3>
        <div className="text-center">
          <h4>Sign in via Google</h4>
          <button onClick={signIn} className="button">
            <img src={google} alt="google login" className="icon"></img>
            <span className="buttonText">Sign in with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
