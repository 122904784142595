import React from "react";
import { Link } from "@reach/router";
import write from "../images/write.svg";

const Definition = (props) => {
  const id = props.id;
  let userLoggedIn = false;
  let name = "";
  if (props.location.state != null) {
    name = props.location.state.name;
    userLoggedIn = props.location.state.userLoggedIn;
  }
  return (
    <div>
      <nav className="navbar navbar-light bg-light justify-content-between">
        <Link
          to="/"
          state={{ userLoggedIn: userLoggedIn, name: name }}
          className="btn btn-primary btn-lg"
        >
          Back
        </Link>
      </nav>
      <div className="container">
        <div id="content" className="def">
          <h1 className="header">
            {id}
            <img src={write} alt="send us your suggestions" />
          </h1>
        </div>

        <h4>Beginner: </h4>
        <div className="input-group input-group-lg">
          <input type="text" className="details-small" />
        </div>

        <h4>Intermediate: </h4>
        <div className="input-group input-group-lg">
          <input type="text" className="details-large" />
        </div>

        <Link
          to={`/review/${id}`}
          type="button"
          className="btn btn-primary btn-lg"
        >
          Submit
        </Link>
      </div>
    </div>
  );
};

export default Definition;
