import React, { useContext } from "react";
import { Link, Redirect } from "@reach/router";
import thanks from "../images/thanks.svg";
import Logout from "./Logout";
import ThemeContext from "./ThemeContext";

const Review = () => {
  const [user] = useContext(ThemeContext);
  return (
    <div>
      <nav className="navbar navbar-light bg-light justify-content-between">
        <Link to="/" className="btn btn-primary btn-lg">
          Back
        </Link>
        {user.userLoggedIn ? (
          <>
            <a className="navbar-brand title" href="/">
              Hi, {user.name}
            </a>
            <Logout />
          </>
        ) : (
          <>
            <Redirect to="/" />
          </>
        )}
      </nav>

      <div className="container">
        <h3 className="text-center">
          Thanks for the suggestion!
          <br />
          <br />
          <span style={{ color: "grey" }}>
            We'll review the term
            <span style={{ color: "#0922fd" }}> {user.id} </span>
            for entry into tech analogies app :)
          </span>
        </h3>
        <div id="content" className="def">
          <h1 className="header">
            <img src={thanks} alt="thank you" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Review;
