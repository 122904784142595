import React from "react";
import { Link } from "@reach/router";
import questions from "../images/questions.svg";
import { SIDEWAYS_DICTIONARY, JIGSAW } from "./fileWithConstants";

const About = () => {
  return (
    <div>
      <nav className="navbar navbar-light bg-light justify-content-between">
        <Link to="/" className="btn btn-primary btn-lg">
          Back
        </Link>
      </nav>
      <h3 className="text-center">
        Inspiration :<a href={SIDEWAYS_DICTIONARY}> Sideways Dictionary </a> by{" "}
        <a href={JIGSAW} className="line">
          {" "}
          Jigsaw{" "}
        </a>
        <span className="line">
          Built to help people understand technology better by offering beginner
          level analogies and intermediate/advanced resources.
        </span>
      </h3>
      <div>
        <img src={questions} alt="About us" className="center" />
      </div>
    </div>
  );
};

export default About;
