import React from "react";
import { Link } from "@reach/router";

const Navbar = () => {
  return (
    <nav className="navbar navbar-light bg-light justify-content-between">
      <Link to="/" className="btn btn-primary btn-lg">
        Back
      </Link>
    </nav>
  );
};

export default Navbar;
