import React from "react";
import { navigate } from "@reach/router";
import { useGoogleLogout } from "react-google-login";
import google from "../images/google.svg";

const clientId =
  "127772657451-iaskros6nqbsbg4an4n3tqf17t907e78.apps.googleusercontent.com";

const Logout = () => {
  const onLogoutSuccess = () => {
    navigate("/", { state: { userLoggedIn: false } });
  };
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
  });

  return (
    <div className="container">
      <button onClick={signOut} className="button">
        <img src={google} className="icon" alt="sign out icon"></img>
        <span className="buttonText">Sign out</span>
      </button>
    </div>
  );
};

export default Logout;
