import "regenerator-runtime/runtime";
import React, { useState, useEffect, useContext } from "react";
import TechAnalogyTable from "./TechAnalogyTable";
import SearchBar from "./SearchBar";
import bulb from "../images/bulb.svg";
import { Link } from "@reach/router";
import Logout from "./Logout";
import { API_ANALOGIES, EMAIL_ACK } from "./fileWithConstants";
import axios from "axios";
import ThemeContext from "./ThemeContext";

const FilterableAnalogyTable = (props) => {
  const [user] = useContext(ThemeContext);
  const [filterText, setFilterText] = useState("");
  const [analogiesList, setAnalogies] = useState([]);
  const [postValue, setPostValue] = useState("");

  // returns a promise
  // wait for this to finish and give me back the data
  async function requestAnalogies() {
    const response = await fetch(API_ANALOGIES);
    const json = await response.json();
    setAnalogies(json.data);
  }

  // run only once
  useEffect(() => {
    requestAnalogies();
    // axios.get(API_ANALOGIES)
    // .then(function (response) {
    //   setAnalogies(response.data.data);
    // })
  }, []);

  const handleFilterTextChange = (filterText) => {
    setFilterText(filterText);
  };

  const sendAckEmail = () => {
    axios.post(EMAIL_ACK, {
      term: postValue,
      email: user.id,
    });
  };

  const handleChange = (text) => {
    setPostValue(text.target.value);
  };

  let newTerm = postValue;
  return (
    <div>
      <nav className="navbar navbar-light bg-light justify-content-between">
        <div className="content">
          <a className="navbar-brand title" href="/">
            techAnalogies
          </a>
          <img src={bulb} alt="idea" />
        </div>
        <div className="menus">
          {user.userLoggedIn ? (
            <>
              <a className="navbar-brand title" href="/">
                Hi, {user.name}
              </a>
              <Logout />
            </>
          ) : (
            <>
              <Link to={`/login/`} className="navbar-brand title">
                Login
              </Link>
              <Link to={`/about/`} className="navbar-brand title">
                About
              </Link>
              <a className="navbar-brand title" href="/">
                Contact us
              </a>
            </>
          )}
        </div>
      </nav>
      <h1 className="header">Please Explain...</h1>
      <SearchBar
        filterText={filterText}
        onFilterTextChange={handleFilterTextChange}
      />
      <TechAnalogyTable
        techAnalogies={analogiesList}
        userLoggedIn={user.userLoggedIn}
        name={user.name}
        filterText={filterText}
      />
      <div className="row justify-content-center">
        <div>
          {user.userLoggedIn ? (
            <>
              <input
                className="form-control not-found"
                type="text"
                placeholder="not found? type here"
                onChange={handleChange}
              />
              <Link
                to={`/review/${newTerm}`}
                onClick={sendAckEmail}
                // state={{ userLoggedIn: user.userLoggedIn }}
                type="button"
                className="btn btn-primary"
              >
                Send us for review
              </Link>
            </>
          ) : (
            <>
              <Link to={`/login/`} type="button" className="btn btn-primary">
                Term not found? Login to Contribute
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterableAnalogyTable;
