export const API_ANALOGIES = "https://api.techanalogies.info/api/analogies/";
export const API_USERS = "https://api.techanalogies.info/api/users";
export const API_USERS_HISTORY =
  "https://api.techanalogies.info/api/usersHistory";

export const SIDEWAYS_DICTIONARY = "https://sidewaysdictionary.com/#/about";
export const JIGSAW = "https://jigsaw.google.com/";
export const CLIENT_ID =
  "290501030675-e7v09tcf4b6583vdgk5vgc8h202bafo9.apps.googleusercontent.com";
export const EMAIL_ACK = "https://api.techanalogies.info/api/users/sendAck";
