import React from "react";

const SearchBar = (props) => {
  const handleFilterTextChange = (e) => {
    props.onFilterTextChange(e.target.value);
  };
  const filterText = props.filterText;
  return (
    <div className="row justify-content-center">
      <div className="form-group">
        <input
          className="form-control search-term"
          type="text"
          placeholder="  search term here  "
          value={filterText}
          onChange={handleFilterTextChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
