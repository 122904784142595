import React from "react";
import { Link } from "@reach/router";

const TechAnalogyRow = (props) => {
  const techAnalogy = props.techAnalogy;
  const userLoggedIn = props.userLoggedIn;
  const name = props.name;
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Link
              to={`/details/${techAnalogy._id}`}
              state={{ userLoggedIn: userLoggedIn, name: name }}
            >
              {techAnalogy.name}
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TechAnalogyRow;
