import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Router } from "@reach/router";
import FilterableAnalogyTable from "./FilterableAnalogyTable";
import Details from "./Details";
import Definition from "./Definition";
import Review from "./Review";
import Login from "./Login";
import AboutPage from "./AboutPage";
import ThemeContext from "./ThemeContext";

const App = () => {
  // top of App function body
  const theme = useState({name: '', id: '', userLoggedIn: ''});

  return (
    <ThemeContext.Provider value={theme}>
    <Router>
      <FilterableAnalogyTable path="/" />
      <Details path="/details/:id" />
      <Definition path="/newDefinition/:id" />
      <Review path="/review/:id" />
      <Login path="/login/" />
      <AboutPage path="/about/" />
    </Router>
    </ThemeContext.Provider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
