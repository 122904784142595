import React from "react";
import TechAnalogyRow from "./TechAnalogyRow";

const TechAnalogyTable = (props) => {
  const filterText = props.filterText;
  const userLoggedIn = props.userLoggedIn;
  const name = props.name;
  const rows = [];
  props.techAnalogies.forEach((techAnalogy) => {
    if (techAnalogy.name.indexOf(filterText) === -1) {
      return;
    }
    rows.push(
      <TechAnalogyRow
        userLoggedIn={userLoggedIn}
        name={name}
        techAnalogy={techAnalogy}
        key={techAnalogy.name}
      />
    );
  });
  return (
    <div className="row justify-content-center">
      <table>
        <tbody>
          <tr>
            <td>{rows}</td>
            <td>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TechAnalogyTable;
