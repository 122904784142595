import React, { useState, useEffect, useContext } from "react";
import { Link } from "@reach/router";
import reading from "../images/reading.svg";
import { API_ANALOGIES } from "./fileWithConstants";
import axios from "axios";
import ThemeContext from "./ThemeContext";
import 'regenerator-runtime/runtime'

const Details = (props) => {
  const [user] = useContext(ThemeContext);
  const [id, setId] = useState("");
  const [beginner, setBeginner] = useState("");
  const [advanced, setAdvanced] = useState("");

    // returns a promise
    // wait for this to finish and give me back the data
    async function requestAnalogiesDetails() {
      const response = await fetch(API_ANALOGIES + props.id)
      const json = await response.json();
      setId(props.id);
      setBeginner(json.data[0].beginner);
      setAdvanced(json.data[0].advanced);
    };

  useEffect(() => {
    requestAnalogiesDetails();
  //   axios.get(API_ANALOGIES + props.id)
  //     .then((term) => {
        // setId(props.id);
        // setBeginner(term.data.data[0].beginner);
        // setAdvanced(term.data.data[0].advanced);
  // })
}, []);

  return (
    <div>
      <nav className="navbar navbar-light bg-light justify-content-between">
        <Link
          to="/"
          className="btn btn-primary btn-lg"
          state={{ userLoggedIn: user.userLoggedIn, name: user.name }}
        >
          Back
        </Link>
        {user.userLoggedIn ? (
          <>
            <Link
              to={`/newDefinition/${id}`}
              id="details-button"
              state={{ userLoggedIn: user.userLoggedIn, name: user.name }}
              className="btn btn-primary btn-sm"
            >
              Have Suggestions? Send us for review
            </Link>
          </>
        ) : (
          <>
            <Link
              to={`/login/`}
              id="details-button"
              className="btn btn-primary btn-sm"
            >
              Have Suggestions? Login to contribute
            </Link>
          </>
        )}
      </nav>
      <div className="container">
        <div className="content">
          <h1 className="header">
            {id}
            <img src={reading} alt="happy reading" />
          </h1>
        </div>

        <h4>Beginner: </h4>
        <div className="card">
          <div className="card-body">
            <h5 className="card-text">{beginner}</h5>
          </div>
        </div>

        <h4>Intermediate: </h4>
        <div className="card">
          <div className="card-body">
            <h5 className="card-text">{advanced}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
